<template>
    <div class="card" :class="{ 'card-flipped': onFlip }">
        <div class="card-inner">
            <div v-if="showCard" class="today-meeting-profile" @click="goToUserDetail">
                <div class="img-wrapper">
                    <div class="watermark">
                        <div class="" style="text-align: center">{{ this.$store.getters.me.phone_number }}</div>
                        <div class="flex-row">
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                        </div>
                        <div class="flex-row">
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                        </div>
                        <div class="flex-row">
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                            <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                        </div>
                        <div class="" style="text-align: center">{{ this.$store.getters.me.phone_number }}</div>
                    </div>
                    <img :src="photoUrls[0]" @error="$event.target.src = $blankProfile" />
                    <div class="nickname-status c-white">
                        <p class="p-l-8 nickname f-medium f-15" :class="{ 'm-b-8': !statusMessage }">{{ nickname }}</p>
                        <p class="p-l-8 f-bold c-pink-deep f-12 m-b-4">{{ statusMessage }}</p>
                    </div>
                </div>
                <div class="today-meeting-profile-button-wrapper">
                    <button
                        class="btn btn-primary"
                        :class="{ accept: status === 'accepted', contact: isPaid }"
                        @click.stop="onClickButton"
                    >
                        {{ buttonLabel }}
                    </button>
                </div>
            </div>
            <div v-if="!showCard" class="today-meeting-card" @click="onClickFlipCard">
                <div class="today-meeting-card-inside center" :class="[gender === 0 ? 'male' : 'female']">
                    <img :src="logo" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import eventService from '@/services/event'
import userService from '@/services/user'

export default {
    name: 'TodayMeetingCard',
    data: () => ({
        onFlip: false,
        status: null,
        forceShowContactLabel: false,
        name: '',
    }),
    props: {
        card: {
            type: Object,
            required: false,
        },
        me: {
            type: String,
        },
    },
    computed: {
        showCard() {
            if (this.me === 'host') {
                return this.card.status !== 'request' || this.status !== 'request'
            }

            if (this.me === 'participant') {
                return this.card.status === 'accepted' || this.card.status === 'done'
            }

            return false
        },
        user() {
            if (this.me === 'host') return this.card.participant_info
            else return this.card.host_info
        },
        gender() {
            return this.user.gender
        },
        logo() {
            return this.gender === 0
                ? require('@/assets/images/logo_male.svg')
                : require('@/assets/images/logo_female.svg')
        },
        nickname() {
            if (this.isPaid && this.user.name) {
                return `${this.name}(${this.user.nickname})`
            } else {
                return this.user.nickname
            }
        },
        photoUrls() {
            return this.user.photo_urls || []
        },
        statusMessage() {
            if (this.card.host_paid && this.card.participant_paid) {
                return '연락처가 교환되었어요!'
            } else {
                if (
                    (this.me === 'host' && this.card.participant_paid) ||
                    (this.me === 'participant' && this.card.host_paid)
                ) {
                    return '상대는 이미 수락했어요!'
                }
            }

            return ''
        },
        buttonLabel() {
            if (this.status === 'checked') {
                return '수락하기'
            } else if (this.status === 'accepted' && this.isPaid) {
                return '상대방 수락 기다리는 중'
            } else if (this.me === 'participant' && !this.isPaid) {
                return '수락하기'
            } else if (this.status === 'accepted' && this.me === 'participant' && this.isPaid) {
                return '상대방 수락 기다리는 중'
            } else if (this.status === 'done') {
                return '연락처 보기'
            }

            return ''
        },
        isPaid() {
            let isPaid

            if (this.me === 'host') {
                isPaid = this.card.host_paid
            } else if (this.me === 'participant') {
                isPaid = this.card.participant_paid
            }

            return isPaid || this.forceShowContactLabel
        },
        success() {
            return this.card.host_paid && this.card.participant_paid
        },
    },
    methods: {
        async onClickFlipCard() {
            if (this.me === 'host' && this.card.is_super === 0) {
                this.$modal
                    .basic({
                        body: `프로필 열람에는 바닐라 ${this.$store.getters.settings.vanilla.open_meeting}개가 소모됩니다.`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.flipCard()
                        }
                    })
            } else {
                this.flipCard()
            }
        },
        async flipCard() {
            try {
                // 프로필 오픈
                if (this.checkVanilla('openProfile')) {
                    await eventService.update(this.card.event_id, this.card.id, 'check')

                    this.card.status = 'checked'
                    this.card.participant_checked = false

                    this.$store.dispatch('loadBadges')
                    this.onFlip = true

                    setTimeout(() => {
                        this.status = 'checked'
                        this.onFlip = false
                    }, 100)

                    // await this.$store.dispatch('updateAllMyPosts')
                    this.$store.commit('updateProcess', {
                        status: 'request',
                        eventId: this.card.event_id,
                        id: this.card.id,
                    })
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        initStatus() {
            this.status = this.card.status
            this.name = this.$nameOrNick(this.user)
        },
        async onClickButton() {
            try {
                if (this.status === 'checked' && this.me === 'host') {
                    if (this.card.is_super === 0) {
                        const idx = await this.$modal.basic({
                            body: `호감을 수락할까요? <br>(바닐라 ${this.$store.getters.settings.vanilla.accept_meeting}개가 사용돼요)`,
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx === 1 && this.checkVanilla('accepted')) {
                            const { msg } = await eventService.update(this.card.event_id, this.card.id, 'accept')
                            this.forceShowContactLabel = true
                            // this.getName()
                            // this.$store.dispatch('loadBadges')

                            await this.$store.dispatch('updateAllMyPosts')
                            this.$store.commit('updateProcess', {
                                status: 'checked',
                                eventId: this.card.event_id,
                                id: this.card.id,
                            })
                            this.status = 'accepted'
                            this.$toast.success(msg)
                        }
                    } else {
                        const idx = await this.$modal.basic({
                            body: '슈퍼 호감을 수락할까요? (무료에요!)',
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx === 1) {
                            const { msg } = await eventService.update(this.card.event_id, this.card.id, 'accept')

                            await this.$store.dispatch('updateAllMyPosts')
                            this.forceShowContactLabel = true
                            // this.getName()
                            // this.$store.dispatch('loadBadges')
                            this.$store.commit('updateProcess', {
                                status: 'checked',
                                eventId: this.card.event_id,
                                id: this.card.id,
                            })
                            this.status = 'accepted'
                            this.$toast.success(msg)
                        }
                    }
                } else if (this.status === 'accepted' && this.me === 'participant') {
                    // const idx = await this.$modal.basic({
                    //     // title: '만남 수락',
                    //     body: '수락하기',
                    //     buttons: [
                    //         {
                    //             label: 'CANCEL',
                    //             class: 'btn-default',
                    //         },
                    //         {
                    //             label: 'CONFIRM',
                    //             class: 'btn-primary',
                    //         },
                    //     ],
                    // })
                    // if (idx === 1 && this.checkVanilla('accepted')) {
                    if (this.checkVanilla('accepted')) {
                        await eventService.update(this.card.event_id, this.card.id, 'participant_paid')
                        this.forceShowContactLabel = true
                        this.getName()
                        this.$store.dispatch('loadBadges')
                        // const bothPaid = this.me === 'host' ? this.card.participant_paid : this.card.host_paid

                        // if (bothPaid) {
                        //     this.$toast.success('연락처가 교환되었어요!')
                        // } else {
                        //     this.$toast.success(
                        //         "상대가 만남을 수락하면 알림을 드려요!\n'프로필>설정'에서 알림을 켤 수 있어요!"
                        //     )
                        // }
                        this.status = 'done'
                        this.$toast.success('연락처가 교환되었어요!')

                        this.$store.commit('updateProcess', {
                            me: this.me,
                            status: 'done',
                            eventId: this.card.event_id,
                            id: this.card.id,
                        })
                    }
                } else {
                    if (this.isPaid) {
                        const { key, msg } = await eventService.success(this.card.event_id, this.card.id)

                        if (key === 'success') {
                            this.$modal.custom({
                                component: 'ModalContact',
                                options: { body: msg },
                            })
                        } else {
                            this.$toast.error(msg)
                        }
                    }
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        goToUserDetail() {
            const photos = this.photoUrls.map(p => ({ url: p }))
            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.user.id,
                    photos: photos,
                    from: 'TodayMeetingCard',
                },
            })
        },
        checkVanilla(status) {
            const { vanilla: currentVanilla } = this.$store.getters.badges
            const { vanilla } = this.$store.getters.settings

            let lackOfVanilla = false
            let insufficientVanilla = 0

            if (this.me === 'host') {
                if (status === 'openProfile') {
                    if (this.card.is_super === 0) {
                        if (currentVanilla < vanilla.open_meeting) {
                            lackOfVanilla = true
                            insufficientVanilla = vanilla.open_meeting - currentVanilla
                        } else {
                            this.$toast.success(`바닐라 ${vanilla.open_meeting}개를 사용하여 프로필을 오픈했어요`)

                            return true
                        }
                    } else {
                        this.$toast.success('슈퍼호감 프로필 카드를 무료로 오픈했어요')

                        return true
                    }
                } else if (status === 'accepted') {
                    if (currentVanilla < vanilla.accept_meeting) {
                        lackOfVanilla = true
                        insufficientVanilla = vanilla.accept_meeting - currentVanilla
                    } else {
                        return true
                    }
                }
            } else if (this.me === 'participant') {
                if (status === 'openProfile') return true
                if (status === 'accepted') {
                    return true
                }
            }

            if (lackOfVanilla) {
                this.$modal
                    .basic({
                        body: `바닐라 ${insufficientVanilla}개가 부족합니다.<br>바닐라를 구매하거나 무료 바닐라를 얻어보세요.`,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'GO_TO_RECOMMEND',
                                class: 'btn-brd',
                            },
                            {
                                label: 'GO_TO_STORE',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$stackRouter.push({ name: 'InvitationFriendPage' })
                        } else if (idx === 2) {
                            this.$stackRouter.push({ name: 'InAppStorePage' })
                        }
                    })
            }
        },
        async getName() {
            const user = await userService.detail(this.user.id)
            this.name = user.name
        },
    },
    mounted() {
        if (this.isPaid) {
            this.getName()
        }
        this.initStatus()
    },
}
</script>
<style lang="scss" scoped>
$column-gap: 12px;
$side-padding: 16px;
$card-width: calc((100vw - #{$side-padding} * 2 - #{$column-gap}) / 2);
$card-height: calc(#{$card-width} * 1.27);

.card,
.card-inner {
    width: $card-width;
    height: $card-height;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.card {
    perspective: 2000px;
    transition: transform 1s;
    box-shadow: none;

    &.card-flipped {
        .card-inner {
            transform: rotateY(180deg);
        }
    }
}

.today-meeting-profile,
.today-meeting-card {
    width: 100%;
    height: 100%;
    background-color: $grey-09;
}

.today-meeting-card {
    transform: rotateY(180deg);
    padding: 4px;
    border-radius: 10px;

    &-inside {
        height: 100%;
        width: 100%;
        border-radius: 6px;

        &.male {
            border: 1px solid $blue-man;
        }

        &.female {
            border: 1px solid $pink-woman;
        }
    }
}

.today-meeting-profile {
    @include flex;

    .img-wrapper {
        width: $card-width;
        height: $card-width;
        position: relative;
        overflow: hidden;

        .watermark {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.1);
            position: absolute;
            height: 100%;
            width: 130%;
            transform: rotate(-45deg);
            top: 0;
            left: -15%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .pn {
                margin-right: 35px;
            }
        }

        img {
            width: 100%;
            height: 100%;
        }

        .nickname-status {
            width: 100%;
            height: 48px;
            position: absolute;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(67, 66, 69, 0), $grey-09 96%);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @include flex;
        }

        .nickname {
            font-weight: 500;
        }
    }

    &-button-wrapper {
        padding: 8px;
        padding-top: 0;
        height: calc(#{$card-height} - #{$card-width});

        @include center;

        button {
            width: 100%;
            height: 36px;
            font-size: 14px;
            font-weight: 500;

            &.accept {
                background-color: $pink-deep;
                color: white;
            }

            &.contact {
                background-color: white;
                color: $pink-deep;
            }
        }
    }
}
</style>
