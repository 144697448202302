<template>
    <!-- <div class="today-meeting-post-detail" :class="{ considerInterestedButton: showPartyMeetingButton }" @scroll="onScroll"> -->
    <div class="today-meeting-post-detail" @scroll="onScroll">
        <StackRouterHeaderBar :class="{ 'show-title': showTitle }" :title="headerTitle" :showTitle="showTitle" />
        <div class="post-detail-photo">
            <div class="watermark">
                <div class="" style="text-align: center">{{ this.$store.getters.me.phone_number }}</div>
                <div class="flex-row">
                    <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                </div>
                <div class="flex-row">
                    <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                </div>
                <div class="flex-row">
                    <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                </div>
                <div class="" style="text-align: center">{{ this.$store.getters.me.phone_number }}</div>
            </div>
            <img v-if="photoURL" class="background" :src="photoURL" />
            <div v-else class="background etc">
                <div class="date f-bold" v-html="$moment(post.expire_at).format('MM.DD')" />
                <div class="region">
                    <div class="state f-medium" v-html="post.region_state" />
                    <div class="detail" v-html="`(${post.region_detail})`" />
                </div>
            </div>
            <!-- <div class="badges">
                <div class="badge-box">
                    <div v-if="post.like > 99" class="badge-contents">
                        <img class="like" src="@/assets/images/like.png" />
                        99+
                    </div>
                    <div v-else class="badge-contents">
                        <img class="like" src="@/assets/images/like.png" />
                        {{ post.like }}
                    </div>
                </div>
            </div> -->
        </div>
        <div class="post-detail-base">
            <div class="post-detail-content" :class="{ 'more-padding': showPartyMeetingButton }">
                <div class="due-created flex-row flex-between f-13 m-b-4">
                    <!-- <p class="due c-primary f-bold">{{ dateAndCategory }}</p> -->
                    <div class="participants flex-row">
                        <div
                            class="gender flex-wrap"
                            :class="{ male: post.host_info.gender === 0, female: post.host_info.gender === 1 }"
                        />
                        <p class="created c-text f-regular">
                            {{ post.participant_count > 99 ? '99+' : post.participant_count }}명 참여 중
                        </p>
                    </div>
                    <span class="created c-text-lighter f-regular">{{ postedAt }}</span>
                </div>
                <div class="category-content f-16-bold m-t-10 m-l-16">
                    <p class="category">{{ post.category }}</p>
                </div>
                <div class="due-created flex-row flex-between f-13 m-b-4">
                    <p class="title m-t-4">{{ post.title }}</p>
                    <!-- <div class="like-box flex-row">
                        <img
                            class="like-btn"
                            v-if="this.like === true"
                            src="@/assets/images/today-meeting/like_active.png"
                            @click="onClickLikeBtn('like')"
                        />
                        <img
                            class="like-btn"
                            v-else
                            src="@/assets/images/today-meeting/like_deactive.png"
                            @click="onClickLikeBtn('like')"
                        />
                    </div>
                    <div class="dislike-box flex-row">
                        <img
                            class="dislike-btn"
                            v-if="this.dislike === true"
                            src="@/assets/images/today-meeting/dislike_active.png"
                            @click="onClickLikeBtn('dislike')"
                        />
                        <img
                            class="dislike-btn"
                            v-else
                            src="@/assets/images/today-meeting/dislike_deactive.png"
                            @click="onClickLikeBtn('dislike')"
                        />
                    </div> -->
                    <img
                        class="delete-btn"
                        v-if="this.$store.getters.me.id === this.post.host_info.id"
                        src="@/assets/images/bin.png"
                        @click="onClickDeleteBtn()"
                    />
                </div>

                <!-- <div class="anonymous-host" @click="clickProfile">
                    <div class="gender-photo" :class="[post.host_info.gender === 0 ? 'bg-male' : 'bg-female']">
                        <img v-if="isBlindProfile" :src="require('@/assets/images/person.svg')" />
                        <img class="mini-photo" v-else :src="post.host_info.primary_photo" />
                    </div>
                    <div class="host items-center m-l-8">
                        <span class="nickname">{{ post.host_info.nickname }} ({{ hostGender }})</span>
                        <span class="m-l-4 f-13 age-dot">• {{ realOrBlindAge }}</span>
                        <div class="verify" v-if="badgeTitle">
                            <span class="verify-info">{{ badgeTitle }}</span>
                            <span>인증 완료</span>
                            <i class="material-icons">admin_panel_settings</i>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="meeting-infos">
                    <div class="info">
                        <img class="m-r-24" :src="require('@/assets/images/today-meeting/date-range-24-px.svg')" />
                        <span>{{ dateAndHowLong }}</span>
                    </div>
                    <div class="info">
                        <img class="m-r-24" :src="require('@/assets/images/today-meeting/room-24-px-3.svg')" />
                        <span>{{ getEventRegion }}</span>
                    </div>
                    <div class="info">
                        <img
                            class="m-r-24"
                            :src="require('@/assets/images/today-meeting/supervised-user-circle-24-px-1.svg')"
                        />
                        <span>{{ post.category }}</span>
                    </div>
                </div> -->

                <div class="intro-content">
                    <p class="desc" v-html="content" />
                </div>
            </div>

            <div v-if="showHostCard" class="post-detail-host m-b-34">
                <div class="title-wrapper m-b-24">
                    <p class="title" v-html="$translate('HOST_PROFILE')" />
                    <i class="material-icons m-l-5 f-18" @click="showHostInfo">info</i>
                </div>
                <div class="cards">
                    <TodayMeetingCard v-if="hostCard" :card="hostCard" :me="'participant'" />
                </div>
            </div>
            <div v-if="showParticipantCard" class="post-detail-participants m-b-34">
                <div class="super-interested-cards" v-if="superInterestedCards.length > 0">
                    <div class="title-wrapper m-b-24">
                        <p class="title" v-html="$translate('SUPER_INTERESTED_CARDS')" />
                        <i class="material-icons m-l-5 f-18" @click="showInterestInfo">info</i>
                    </div>
                    <div class="cards">
                        <TodayMeetingCard
                            v-for="card in superInterestedCards"
                            :card="card"
                            :me="'host'"
                            :key="card.id"
                        />
                    </div>
                    <!-- <span class="no-cards" v-if="superInterestedCards.length === 0">아직 관심을 보인 분이 없어요</span> -->
                </div>
                <div class="interested-cards" v-if="interestedCards.length > 0">
                    <div class="title-wrapper m-b-24">
                        <p class="title" v-html="$translate('INTERESTED_CARDS')" />
                        <i class="material-icons m-l-5 f-18" @click="showInterestInfo">info</i>
                    </div>
                    <div class="cards">
                        <TodayMeetingCard v-for="card in interestedCards" :card="card" :me="'host'" :key="card.id" />
                    </div>
                    <!-- <span class="no-cards" v-if="interestedCards.length === 0">아직 관심을 보인 분이 없어요</span> -->
                </div>
            </div>
        </div>
        <!-- <PartyMeetingButton
            v-if="showPartyMeetingButton"
            :disabled="disabled"
            @click="onClickInterestButton"
        /> -->
        <div class="interest-info" v-if="showPartyMeetingButton" :disabled="disabled">
            <div class="send-interest-info">
                <span class="white-b">호감 보내기</span>
                <i class="material-icons f-20" @click="showInterestInfo">info</i>
            </div>
        </div>
        <div class="party-meeting-button" v-if="showPartyMeetingButton" :disabled="disabled">
            <button
                class="btn btn-brd"
                @click="onClickInterestButton('normalInterest')"
                v-html="'호감 보내기 <br>(무료)'"
            />
            <button
                class="btn btn-primary"
                @click="onClickInterestButton('superInterest')"
                v-html="
                    `슈퍼 호감 보내기 <br> (바닐라 ${this.$store.getters.settings.vanilla.participate_meeting}개 사용)`
                "
            />
        </div>
        <!-- <div class="party-meeting-button" v-if="disabled=true && showPartyMeetingButton">
            <button
                class="btn"
                :class="'disabled'"
                v-html="'상대방의 수락을 기다리고 있습니다.'"
            />
        </div> -->
    </div>
</template>
<script>
import TodayMeetingCard from '@/routes/today-meeting/TodayMeetingCard'
// import PartyMeetingButton from './components/PartyMeetingButton'
import eventService from '@/services/event'
import badgeService from '@/services/verification-badge'

export default {
    name: 'TodayMeetingPostDetailPage',
    components: {
        TodayMeetingCard,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
        filterType: {
            type: Object,
            required: true,
        },
        from: {
            type: String,
        },
    },
    data: () => ({
        showTitle: false,
        disabled: false,
        allBadges: [],
        summaryBadges: [],
        badgeTitle: '',
        bottomCta: '',
        // like: false,
        // dislike: false,
    }),
    created() {
        this.disabled = this.dateAndCategory === '마감' || this.post.participated
        this.loadHostBadges()
        // this.getLike()

        // hackle event
        // this.$hackleClient.onReady(() => {
        //     const hackleUser = this.$store.getters.hackleUser
        // const word = this.$hackleClient.variation(10, hackleUser)
        // this.bottomCta = word === 'A' ? '관심 있어요' : '호감 보내기 (무료)'
        // })
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'todayMeetingEvent',
                option: {
                    funnel: this.isBlindProfile ? 'click_blind_profile_post' : 'click_mini_profile_post',
                },
            },
        })
    },
    computed: {
        getEventRegion() {
            const region =
                this.$store.getters.eventRegions.find(region => region.id === this.post.event_region_id) || {}
            const seoulOrKyungKi = [1, 2].includes(region.state_id)
            return `${this.post.state}${seoulOrKyungKi ? `(${region.name})` : ''}`
        },
        isBlindProfile() {
            return this.post.profile_style === 'privacy' || !this.post.profile_style
        },
        realOrBlindAge() {
            const age = this.post.host_info.age

            if (this.isBlindProfile) return this.$options.filters.blindAge(age)
            else return `${age}세`
        },
        headerTitle() {
            return '셀소'
        },
        content() {
            return this.post.content.split(/\n/).join('<br>')
        },
        showPartyMeetingButton() {
            // 내가 작성한 글 / 이미 신청한 글의 경우 관심있어요 버튼이 보이면 안됨
            return this.post.host_info.id !== this.$store.getters.me.id && !this.post.participant
        },
        showHostCard() {
            return this.post.participant
        },
        showParticipantCard() {
            return this.post.participants
        },
        hostGender() {
            const gender = this.$options.filters.gender(this.post.host_info.gender)
            return this.$translate(gender)[0]
        },
        dateAndCategory() {
            const today = this.$moment().format('YYYY-MM-DD')
            const expireAt = this.$moment(this.post.expire_at)
            const diff = expireAt.diff(today, 'days')
            let expireAtToString

            if (diff < 0) {
                return '마감'
            } else {
                if (diff === 0) expireAtToString = '오늘 만나요'
                if (diff === 1) expireAtToString = '내일 만나요'

                const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토']

                return `${expireAtToString} (${expireAt.format('MM.DD')} ${dayOfWeek[expireAt.day()]})`
            }
        },
        dateAndHowLong() {
            const today = this.$moment().format('YYYY-MM-DD')
            const expireAt = this.$moment(this.post.expire_at)
            const diff = expireAt.diff(today, 'days')

            let expireAtToString
            if (diff < 0) return '마감'
            else {
                if (diff === 0) expireAtToString = '오늘'
                if (diff === 1) expireAtToString = '내일'

                const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토']
                return `${expireAt.format('MM.DD')} ${dayOfWeek[expireAt.day()]} (${expireAtToString})`
            }
        },
        postedAt() {
            const days = this.$moment().diff(this.post.created_at, 'days')
            const hours = this.$moment().diff(this.post.created_at, 'hours')
            const mins = this.$moment().diff(this.post.created_at, 'minutes')

            if (days) {
                return `${days}일 전 게시`
            } else if (hours) {
                return `${hours}시간 전 게시`
            } else {
                return `${mins}분 전 게시`
            }
        },
        // showCardDue() {
        //     return this.dateAndCategory === '마감'
        //         ? this.dateAndCategory
        //         : this.dateAndCategory.slice(0, 2) + ' 자정까지만 확인할 수 있어요!'
        // },
        hostCard() {
            if (this.post.participant) {
                const hostInfo = { host_info: { ...this.post.host_info } }
                return { ...this.post.participant, ...hostInfo }
            }
            return {}
        },
        superInterestedCards() {
            return this.post.supers
        },
        interestedCards() {
            return this.post.normals
        },
        // bottomButtonLabel() {
        //     if (this.dateAndCategory === '마감') return '마감'

        //     return this.$translate('SHOW_INTEREST')
        // },
        photoURL() {
            if (this.post.photo_url.includes('medium')) {
                return this.post.photo_url.replace(/medium/, 'original')
            }
            if (this.post.category === '기타') return false

            return this.post.photo_url
        },
    },
    methods: {
        // async getLike() {
        //     try {
        //         this.$loading(true)
        //         const res = await eventService.getLike(this.post.id)
        //         if (res.data !== null && res.data.like === true) {
        //             this.like = true
        //         }
        //         if (res.data !== null && res.data.like === false) {
        //             this.like = false
        //         }
        //         if (res.data !== null && res.data.dislike === true) {
        //             this.dislike = true
        //         }
        //         if (res.data !== null && res.data.dislike === false) {
        //             this.dislike = false
        //         }
        //     } catch (e) {
        //         this.$toast.error(e)
        //     } finally {
        //         this.$loading(false)
        //     }
        // },
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 200
        },
        onClickInterestButton(status) {
            // hackle event 전송
            this.$hackleClient.onReady(() => {
                const hackleUser = this.$store.getters.hackleUser
                this.$hackleClient.track('todaymeet_bottomcta_click', hackleUser)
            })
            if (this.$store.getters.me.gender === this.post.host_info.gender) {
                this.$modal.basic({
                    // title: '글쓴이에게 호감을 보냅니다.',
                    body: '이성 회원에게만 호감을 보낼 수 있어요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                if (status === 'normalInterest') {
                    this.$modal
                        .basic({
                            // title: '글쓴이에게 호감을 보냅니다.',
                            body: '글쓴이에게 호감을 보냅니다.',
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 1) {
                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'todayMeetingEvent',
                                        option: {
                                            funnel: 'complete_interest_cta',
                                        },
                                    },
                                })
                                this.requestInteresting(status)
                                this.disabled = true
                            }
                        })
                } else {
                    const { vanilla: currentVanilla } = this.$store.getters.badges
                    const { vanilla } = this.$store.getters.settings

                    if (currentVanilla < vanilla.participate_meeting) {
                        this.$modal
                            .basic({
                                body: `바닐라 ${
                                    vanilla.participate_meeting - currentVanilla
                                }개가 부족합니다.<br>바닐라를 구매하거나 무료 바닐라를 얻어보세요.`,
                                buttons: [
                                    {
                                        label: 'CANCEL',
                                        class: 'btn-default',
                                    },
                                    {
                                        label: 'GO_TO_RECOMMEND',
                                        class: 'btn-brd',
                                    },
                                    {
                                        label: 'GO_TO_STORE',
                                        class: 'btn-primary',
                                    },
                                ],
                            })
                            .then(idx => {
                                if (idx === 1) {
                                    this.$stackRouter.push({ name: 'InvitationFriendPage' })
                                } else if (idx === 2) {
                                    this.$stackRouter.push({ name: 'InAppStorePage' })
                                }
                            })
                    } else {
                        this.$modal
                            .basic({
                                body: `바닐라 ${this.$store.getters.settings.vanilla.participate_meeting}개를 사용해 글쓴이에게 호감을 보낼게요! 글쓴이가 무료로 수락할 수 있어요.`,
                                buttons: [
                                    {
                                        label: 'CANCEL',
                                        class: 'btn-default',
                                    },
                                    {
                                        label: 'CONFIRM',
                                        class: 'btn-primary',
                                    },
                                ],
                            })
                            .then(idx => {
                                if (idx === 1) {
                                    this.$nativeBridge.postMessage({
                                        action: 'sendFirebaseEvent',
                                        value: {
                                            category: 'todayMeetingEvent',
                                            option: {
                                                funnel: 'complete_interest_cta',
                                            },
                                        },
                                    })
                                    this.requestInteresting(status)
                                    this.disabled = true
                                }
                            })
                    }
                }
            }
        },
        // async onClickLikeBtn(likeStatus) {
        //     if (this.$store.getters.me.id === this.post.host_info.id) {
        //         this.$modal
        //             .basic({
        //                 body: `본인의 글에는 추천/비추천을 할 수 없어요`,
        //                         buttons: [
        //                             {
        //                                 label: 'CONFIRM',
        //                                 class: 'btn-primary',
        //                             },
        //                         ],
        //             })
        //     } else {
        //         try {
        //             this.$loading(true)
        //             const res = await eventService.updateLike(this.post.id, likeStatus)

        //             // this.$store.dispatch('loadBadges')
        //             await this.$store.dispatch('loadTodayMeetingPosts', this.filterType)
        //             this.post.like = res.like_count
        //             return res
        //         } catch (e) {
        //             // this.$toast.error(e.msg)
        //         } finally {
        //             this.$loading(false)
        //             this.getLike()
        //         }
        //     }
        // },
        async requestInteresting(status) {
            if (status === 'normalInterest') {
                try {
                    this.$loading(true)
                    const payload = { is_super: false }
                    const {
                        event_participant: participants,
                        participant_count: participantCount,
                        msg,
                    } = await eventService.request(this.post.id, payload)
                    this.$store.commit('updateClickInterest', participants)
                    this.$store.dispatch('loadBadges')
                    await this.$store.dispatch('loadTodayMeetingPosts', this.filterType)
                    this.post.participant_count = participantCount

                    this.$toast.success(msg)
                } catch (e) {
                    this.$hooks.handlePossibleVanillaShortageError(e.data)
                } finally {
                    this.$loading(false)
                }
            } else {
                try {
                    this.$loading(true)
                    const payload = { is_super: true }
                    const {
                        event_participant: participants,
                        participant_count: particpantCount,
                        msg,
                    } = await eventService.request(this.post.id, payload)
                    this.$store.commit('updateClickInterest', participants)
                    this.$store.dispatch('loadBadges')
                    await this.$store.dispatch('loadTodayMeetingPosts', this.filterType)
                    this.post.participant_count = particpantCount

                    this.$toast.success(msg)
                } catch (e) {
                    this.$hooks.handlePossibleVanillaShortageError(e.data)
                } finally {
                    this.$loading(false)
                }
            }
        },

        // showToast() {
        //     // if (this.showCardDue === '마감') {
        //     //     const msg = '휴면/탈퇴한 회원의 프로필은 사라져요'
        //     //     this.$toast.success(msg)
        //     // } else {
        //     //     const msg = this.showCardDue + '\n' + '휴면/탈퇴한 회원의 프로필은 사라져요'
        //     //     this.$toast.success(msg)
        //     // }
        //     const msg = '휴면/탈퇴한 회원의 프로필은 사라져요'
        //     this.$toast.success(msg)
        // },
        showHostInfo() {
            const msg = '글쓴이가 수락하면 프로필을 볼 수 있어요. <br>프로필 확인 후 수락하면 연락처가 교환됩니다!'
            this.$toast.success(msg)
        },
        showInterestInfo() {
            // const msg = '호감 툴팁 표시 예정'
            // this.$toast.success(msg)
            this.$modal.custom({
                component: 'ModalPartyInterestInfo',
                // options: {
                //     photoURL,
                // },
            })
        },
        clickProfile() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'todayMeetingEvent',
                    option: {
                        funnel: this.isBlindProfile ? 'click_blind_profile_detail' : 'click_mini_profile_detail',
                    },
                },
            })

            this.$modal.custom({
                component: 'ModalProfileView',
                options: {
                    profileStyle: this.post.profile_style,
                    hostInfo: this.post.host_info,
                    badgeTitle: this.badgeTitle,
                    allBadges: this.allBadges,
                },
            })
        },
        async loadHostBadges() {
            const res = await badgeService.get(this.post.host_info.id)
            this.allBadges = res
            this.summaryBadges = res.filter(r => {
                return (r.display_method || '').includes('summary')
            })
            this.summaryBadges.forEach((badge, idx) => {
                if (idx === this.summaryBadges.length - 1) this.badgeTitle += this.summaryBadgeTitle(badge.name)
                else this.badgeTitle += `${this.summaryBadgeTitle(badge.name)}, `
            })
        },
        summaryBadgeTitle(name) {
            return name.replace('인증', '')
        },
        async onClickDeleteBtn() {
            try {
                const idx = await this.$modal.basic({
                    body: '게시물을 삭제하시겠습니까?',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 1) {
                    await eventService.cancelGroupMeet(this.post.id)
                    this.$toast.success('게시물을 삭제했습니다.')
                    this.$store.commit('updateRemovedPost', this.post.id)
                    this.$stackRouter.pop()
                    await this.$store.dispatch('loadTodayMeetingPosts', this.filterType)
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.today-meeting-post-detail {
    overflow-y: auto;

    .gender {
        margin-right: 6px;
        width: 10px;
        height: 10px;
        border-radius: 40%;
        align-self: center;

        &.male {
            background-color: $blue-man;
            color: $blue-man;
        }

        &.female {
            background-color: $pink-woman;
            color: $pink-woman;
        }
    }

    .info-user {
        margin-top: 8px;
        &.not_all {
            margin-top: 0px;
        }
        .region {
            font-size: 12px;
            color: $grey-07;
            @include f-medium;
            margin-bottom: 2px;
        }
    }

    &.considerInterestedButton {
        $bottom-button-height: 81px;
    }
}

::v-deep .stack-router-header-bar {
    z-index: 3;
    position: fixed;
    top: 0;
    background-color: transparent;

    .left-btn > .material-icons {
        color: white;
    }

    &.show-title {
        background-color: white;
        border-bottom: solid 1px $grey-03;

        .material-icons {
            color: $grey-08 !important;
        }
    }
}

.post-detail-photo {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vw;

    .watermark {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.1);
        position: absolute;
        height: 100%;
        width: 130%;
        transform: rotate(-45deg);
        top: 0;
        left: -15%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .pn {
            margin-right: 35px;
        }
    }

    .background {
        width: 100%;
        height: 100%;

        &.etc {
            color: white;
            background-image: linear-gradient(135deg, #ba90ff, $purple-primary);
            display: flex;
            flex-direction: column;
            justify-content: center;

            .date {
                padding: 1px 10px;
                background: white;
                font-size: 14px;
                color: $purple-primary;
                width: fit-content;
                margin: 0 auto 12px auto;
                border-radius: 6px;
            }
            .region {
                font-size: 18px;
                text-align: center;
            }
        }
    }

    .badges {
        position: absolute;
        right: 8px;
        bottom: 16px;
        // display: flex;
        // flex-wrap: wrap;
        // flex-direction: column;

        .badge-box {
            font-size: 15px;
            color: white;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 6px;
            padding: 2px 2px;
            display: flex;
            margin-right: 10px;
            text-align: center;
            @include f-medium;

            .badge-contents {
                img {
                    height: 15px;
                    width: 15px;
                    margin: 0 6px;
                }

                padding-right: 3px;
            }
        }
    }
}

.post-detail-content {
    padding: 28px 0;
    line-height: normal;
    letter-spacing: -0.2px;
    //border-bottom: 1px solid $grey-02;

    &.more-padding {
        $bottom-buttom-height: 80px;
        padding-bottom: calc(#{$bottom-buttom-height} + 52px);
    }

    .due-created {
        padding: 0 16px;
    }

    .category-content {
        padding: 0 12px;
        width: fit-content;
        border: $purple-primary 2px solid;
        border-radius: 10px;
        text-align: center;
    }

    .title {
        color: black;
        font-size: 20px;
        font-weight: 500;
        width: 90%;
        padding-top: 13px;
        // padding: 0 16px 28px 16px;

        @include f-medium;
    }

    .category {
        color: black;
        font-size: 14px;
        font-weight: bold;
        // padding: 0 16px 28px 16px;

        @include f-medium;
    }

    .like-btn {
        font-size: 12px;
        color: white;
        // border-radius: 6px;
        height: 15px;
        width: 15px;
        margin: 25px 0;
    }

    .dislike-btn {
        font-size: 12px;
        color: white;
        // border-radius: 6px;
        height: 15px;
        width: 15px;
        margin: 25px 0;
    }

    .delete-btn {
        font-size: 12px;
        color: white;
        // border-radius: 6px;
        height: 15px;
        width: 15px;
        margin: 25px 0;
    }

    .anonymous-host {
        z-index: 2;
        padding: 0 16px 28px 16px;
        font-weight: 500;
        letter-spacing: -0.2px;
        border-bottom: 1px solid $grey-02;

        @include flex-row;
        @include f-medium;

        .gender-photo {
            $gender-photo-size: 24px;
            height: 24px;
            width: 24px;
            border-radius: 40%;

            .mini-photo {
                @extend .gender-photo;
            }

            @include center;
        }

        .host {
            .nickname {
                color: black;
                @include f-regular;
            }
            .age-dot {
                color: $grey-07;
            }
        }
    }

    .verify {
        margin-top: 6px;
        i {
            color: $grey-07;
            font-size: 18px;
            margin-left: 3px;
        }

        color: $grey-07;
        font-size: 12px;
        display: flex;
        align-items: center;

        .verify-info {
            @include f-bold;
            text-decoration: underline;
            margin-right: 3px;
        }
    }

    .keywords {
        display: flex;
        flex-flow: row wrap;

        .keyword {
            color: $grey-09;
            font-weight: 500;
            font-size: 13px;
            background-color: $grey-02;
            border-radius: 6px;
            padding: 3px 10px;
            @include f-medium;

            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    .desc {
        line-height: 1.6;
        font-size: 15px;
        color: black;
        margin-bottom: 30px;
        @include f-regular;

        &.self-content {
            margin-bottom: 16px;
        }
    }
}

.meeting-infos {
    margin: 28px 16px 8px 16px;
    border-bottom: 1px solid $grey-02;

    .info {
        margin-bottom: 20px;
        font-size: 14px;
        @include f-medium;
        color: $grey-09;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.intro-content {
    padding: 28px 16px 0 16px;
}

.post-detail-host,
.post-detail-participants {
    border-top: 1px solid $grey-02;
    padding: 28px 16px;
    line-height: normal;
    letter-spacing: -0.2px;

    .interested-cards {
        margin-top: 30px;
    }

    .title-wrapper {
        @include flex-row;
        @include items-center;
    }

    .title {
        color: black;
        font-weight: 500;
        font-size: 18px;

        @include f-medium;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 12px;
        column-gap: 12px;

        .no-cards {
            color: $grey-08;
            font-size: 14px;
        }
    }
}

// .bottom-button {
//     position: fixed;
// }

.interest-info {
    &.nonFixed {
        position: static !important;
        padding: 0;
        border: none !important;
    }
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    padding-top: 16px;
    border-top: 1px solid $grey-02;
    display: flex;
    width: 100%;
    background: white;
    z-index: 3;

    .send-interest-info {
        margin: 0 0 0 auto;
        padding-right: 15px;

        .white-b {
            font-size: 13px;
            vertical-align: top;
            color: $grey-08;
            margin-right: 3px;
            @include f-bold;
        }
    }
}
.party-meeting-button {
    &.nonFixed {
        position: static !important;
        padding: 0;
        border: none !important;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 20px;
    // border-top: 1px solid $grey-02;
    display: flex;
    align-items: flex-end;
    width: 100%;
    background: white;
    z-index: 3;

    button {
        height: 48px;
        font-size: 13px;
        width: 50%;

        &.btn-social {
            color: #fff;
            background: $social;
        }
    }
}
</style>
